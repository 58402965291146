// UNCOMMENT TS AND REPLACE JS WHEN TRANSFER blocksure-core TO TS

// @flow
// import type {ApiResponse} from './BaseApiClient';
import BaseApiClient from './BaseApiClient';

export default class PolicyholderApiClient extends BaseApiClient {
    constructor(...args) {
        super('/policyholder/api/v1/', ...args);
    }

    // async createPolicyholder<T>(policyholder: *, productIds: string[]): Promise<T> {
    //     const response: ApiResponse<T> = await super.handlePost(this.basepath, {policyholder, productIds});
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async createPolicyholder(policyholder, productIds, countryLockedPhone) {
        const response = await super.handlePost(this.basepath, {policyholder, productIds, countryLockedPhone});
        super.handleResponse(response);
        return response.data;
    }

    // async getPolicyholderToken(email) {
    //     // avoidReauthentication param is meant to avoid going to an infinite loop by trying to refresh the token
    //     const config = {headers: {avoidReauthentication: true}};
    //     const response: ApiResponse<T> = await super.handleGet(`${this.authpath}policyholder/${email}`, config);
    //     super.handleResponse(response);
    //     return response.data.token;
    // }

    async getPolicyholderToken(email) {
        // avoidReauthentication param is meant to avoid going to an infinite loop by trying to refresh the token
        const param = encodeURIComponent(email);
        const config = {headers: {avoidReauthentication: true}};
        const response = await super.handleGet(`${this.authpath}policyholder/${param}`, config);
        super.handleResponse(response);
        return response.data.token;
    }

    // async checkIfPolicyholderExists<T>(email: string): Promise<?boolean> {
    //     try {
    //         const response: ApiResponse<T> = await super.handleGet(`${this.basepath}${email}/exists`);
    //         super.handleResponse(response);
    //         return !!response.data;
    //     } catch (e) {
    //         // TODO: Should throw an actual error
    //         return null;
    //     }
    // }

    // async getPolicyholder<T>(email: string): Promise<T> {
    //     const response: ApiResponse<T> = await super.handleGet(`${this.basepath}${email}`);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async getPolicyholder(email) {
        const param = encodeURIComponent(email);
        const response = await super.handleGet(`${this.basepath}${param}`);
        super.handleResponse(response);
        return response.data;
    }

    // async updatePolicyholder<T>(id: string, policyholderUpdate: *): Promise<T> {
    //     policyholderUpdate.id = id;
    //     const response: ApiResponse<T> = await super.handlePost(`${this.basepath}${id}`, policyholderUpdate);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async updatePolicyholder(id, policyholderDto, countryLockedPhone) {
        policyholderDto.id = id;
        const response = await super.handlePost(`${this.basepath}${id}`, {policyholderDto, countryLockedPhone});
        super.handleResponse(response);
        return response.data;
    }

    // async updateLocal<T>(id: string, data: {}): Promise<T> {
    //     const response: ApiResponse<T> = await super.handlePost(`${this.basepath}${id}/local`, data);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async updateLocal(id, data) {
        const response = await super.handlePost(`${this.basepath}${id}/local`, data);
        super.handleResponse(response);
        return response.data;
    }

    // async search(query): Promise<T> {
    //     const config = {params: query};
    //     const response: ApiResponse<T> = await super.handleGet(this.basepath, config);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async search(query) {
        const config = {params: query};
        const response = await super.handleGet(this.basepath, config);
        super.handleResponse(response);
        return response.data;
    }

    // async getSchema(country): Promise<T> {
    //     const response: ApiResponse<T> = await super.handleGet(`${this.basepath}schema/${country}`);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async getSchema(country, type = 'Individual') {
        const params = {type};
        const response = await super.handleGet(`${this.basepath}schema/${country}`, params);
        super.handleResponse(response);
        return response.data;
    }
}
