// UNCOMMENT TS AND REPLACE JS WHEN TRANSFER blocksure-core TO TS

// @flow
// import type {ApiResponse} from './BaseApiClient';
import BaseApiClient from './BaseApiClient';

// type UpdateUser = CreateUser & { +promptToPassword: boolean }

// type CreateUser = {
//     +email: string,
//     +firstName: string,
//     +lastName: string,
//     +password: string,
//     +roles: string,
// }
export default class UserApiClient extends BaseApiClient {
    constructor(...args) {
        super('/auth/api/v1/user', ...args);
    }
    // async createUser<T>(user: CreateUser): Promise<T> {
    //     user.roles = user.roles || [];
    //     const response: ApiResponse<T> = await super.handlePost(this.basepath, user);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async createUser(user) {
        user.roles = user.roles || [];
        const response = await super.handlePost(this.basepath, user);
        super.handleResponse(response);
        return response.data;
    }

    // async listUsers<T>(): Promise<T> {
    //     const response: ApiResponse<T> = await super.handleGet(this.basepath);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async listUsers(shouldPaginate = null, includePolicyholder = null, limit = 10, offset = 0) {
        let config = { params: {} };
        if (shouldPaginate !== null) config = {params: {limit, offset, shouldPaginate}};
        if (includePolicyholder !== null) config = { params: { includePolicyholder, ...config.params} };
        const response = await super.handleGet(this.basepath, config);
        super.handleResponse(response);
        return response.data;
    }

    // async getUser<T>(emailOrUserId: string): Promise<T> {
    //     const response: ApiResponse<T> = await super.handleGet(`${this.basepath}/${emailOrUserId}`);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async getUser(emailOrUserId) {
        const param = encodeURIComponent(emailOrUserId);
        const response = await super.handleGet(`${this.basepath}/${param}`);
        super.handleResponse(response);
        return response.data;
    }

    // async deleteUser<T>(id: number): Promise<T> {
    //     const response: ApiResponse<T> = await this.axios.delete(`${this.basepath}/${id}`);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async deleteUser(id) {
        const response = await this.axios.delete(`${this.basepath}/${id}`);
        super.handleResponse(response);
        return response.data;
    }

    // async updateUser<T>(id: number, userUpdate: UpdateUser): Promise<T> {
    //     userUpdate.roles = userUpdate.roles || [];
    //     const response: ApiResponse<T> = await super.handlePost(`${this.basepath}/${id}`, userUpdate);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async updateUser(id, userUpdate) {
        userUpdate.roles = userUpdate.roles || [];
        const response = await super.handlePost(`${this.basepath}/${id}`, userUpdate);
        super.handleResponse(response);
        return response.data;
    }

    // async triggerPasswordReset(email, forwardUrl, productId, appName, lng = undefined) {
    //     const config = {params: {forwardUrl, productId, lng}};
    //     const response: ApiResponse<T> = await super.handleGet(`${this.basepath}/passwordreset/${email}?app-name=${appName}`, config);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async triggerPasswordReset(email, forwardUrl, productId, appName, lng = undefined) {
        const param = encodeURIComponent(email);
        const config = {params: {forwardUrl, productId, lng}};
        const response = await super.handleGet(`${this.basepath}/passwordreset/${param}?app-name=${appName}`, config);
        super.handleResponse(response);
        return response.data;
    }

    async triggerEmailUpdate(email, newEmail, rejectForwardUrl, confirmForwardUrl, productId, appName, lng = undefined) {
        const currentEmailParam = encodeURIComponent(email);
        const newEmailParam = encodeURIComponent(newEmail);
        const config = {params: {rejectForwardUrl, confirmForwardUrl, productId, lng}};
        const response = await super.handleGet(`${this.basepath}/emailupdate/${currentEmailParam}/${newEmailParam}?app-name=${appName}`, config);
        super.handleResponse(response);
        return response.data;
    }

    // async hasSetPassword() {
    //     const response: ApiResponse<T> = await super.handleGet(`${this.basepath}/password`);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async hasSetPassword() {
        const response = await super.handleGet(`${this.basepath}/password`);
        super.handleResponse(response);
        return response.data;
    }

    // async updateCurrentUserPassword(password) {
    //     const response: ApiResponse<T> = await super.handlePost(`${this.basepath}/password`, {password});
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async updateCurrentUserPassword(password) {
        const response = await super.handlePost(`${this.basepath}/password`, {password});
        super.handleResponse(response);
        return response.data;
    }
}

export const errors = ['Passwords must be at least 10 characters long.',
    'Passwords must be less than or equal to 50 characters long.',
    'Passwords must contain at least one upper case character.',
    'Passwords must contain at least one lower case character.',
    'Passwords must contain at least one number.'];

// Ensure the front-end validation matches back-end. Back-end is contained in UserApiService.kt
export function validatePasswords(password) {
    if (!password || password === '') return {short: errors[0], capital: errors[2], lower: errors[3], number: errors[4]};
    let passwordCheck;
    if (password.length < 10) passwordCheck = {short: errors[0]};
    if (password.length > 50) passwordCheck = {...passwordCheck, long: errors[1]};
    if (!/[A-Z]/.test(password)) passwordCheck = {...passwordCheck, capital: errors[2]};
    if (!/[a-z]/.test(password)) passwordCheck = {...passwordCheck, lower: errors[3]};
    if (!/[0-9]/.test(password)) passwordCheck = {...passwordCheck, number: errors[4]};
    return passwordCheck;
}
