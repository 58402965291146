/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import defaultJsonLang from '../translations/exception-translation-en.json';

const generateTranslation = (i18next) => {
    const key = i18next.language || localStorage.getItem('i18nextLng');
    const currentJsonLang = require(`../translations/exception-translation-${key}.json`);
    return currentJsonLang;
};

export const generateError = (i18next, response, defaultTranslation) => {
    if (!response?.errorKey) return defaultTranslation;
    if (response.errorKey === 'ERR_MULTIPLE_ERRORS') {
        response.errors.forEach(e =>
            console.log(translateAndReplace(e.errorKey, e.description, e.parameters, i18next))
        );
    }

    return translateAndReplace(response.errorKey, response.description, response.parameters, i18next);
};

function translateAndReplace(errorKey, description, parameters, i18next) {
    const currentJsonLang = generateTranslation(i18next) || defaultJsonLang;
    let errorMessage = currentJsonLang[errorKey] || description;

    try {
        const params = Object.keys(parameters);
        params.forEach((param) => {
            const responseParam = parameters[param];
            errorMessage = errorMessage.replace(param, responseParam);
        });
    } catch (error) {
        console.log(error);
    }
    return errorMessage;
}

export const generateErrorMessage = (error, message) => error.message || error.toString() || message;
