import BaseApiClient from './BaseApiClient';

export const Bound = 'Bound';
export const Quoted = 'Quoted';

export const defaultLoad = [
    'load.breakdown',
];

export default class PolicyApiClient extends BaseApiClient {
    constructor(...args) {
        super('/policy/api/v1/', ...args);
    }

    async search(query, limit = 500, offset = 0, load = defaultLoad) {
        const config = {params: {limit, offset, ...query}};
        this.addLoadParams(load, config);
        const response = await super.handleGet(this.basepath, config);
        super.handleResponse(response);
        return response.data;
    }

    // Returns mock data for RFQ
    async searchWithMockRfq(query, limit = 500, offset = 0, load = defaultLoad) {
        const featureToggles = await super.handleGet('/infra/api/v1/feature');
        if (featureToggles.data.mockRfq) {
            query.statuses = Quoted;
            const config = {params: {limit, offset, ...query}};
            this.addLoadParams(load, config);
            const response = await
            this.axios.get(this.basepath, config);
            super.handleResponse(response);
            response.data.items = response.data.items && response.data.items.map(policy => {
                policy.status = 'RFQ';
                return policy;
            });
            return response.data;
        } else {
            throw new Error('Mock data is not allowed in this environment.');
        }
    }

    async searchTransactions(query, limit = 500, offset = 0) {
        const config = {params: {limit, offset, ...query}};
        const response = await super.handleGet(`${this.basepath}transaction`, config);
        super.handleResponse(response);
        return response.data;
    }

    async quote(productId, meta, submission, duration, effectiveFrom, policyholderId, payFrequency = null, attachments = [], previousQuoteId, preview = false) {
        const data = {productId, meta, submission, duration, effectiveFrom, policyholderId, payFrequency, attachments, previousQuoteId, preview};
        const response = await super.handlePost(`${this.basepath}`, data);
        super.handleResponse(response);
        return response.data;
    }

    async quoteWithAttachments(productId, meta, submission, duration, effectiveFrom, policyholderId, payFrequency = null, previousQuoteId, attachments, files, preview = false) {
        const formData = new FormData();
        const data = {productId, meta, submission, duration, effectiveFrom, policyholderId, payFrequency, attachments, previousQuoteId, preview};
        formData.append('policy', JSON.stringify(data));
        files.forEach(it => formData.append('files', it.file, it.name));
        const headers = {'content-type': 'multipart/form-data'};
        const response = await super.handlePost(`${this.basepath}form`, formData, {headers});
        super.handleResponse(response);
        return response.data;
    }

    async bind(productId, meta, submission, duration, effectiveFrom, policyholderId, payFrequency = null, previousQuoteId, attachments, files, paymentSource) {
        const formData = new FormData();
        const data = {productId, meta, submission, duration, effectiveFrom, policyholderId, payFrequency, attachments, previousQuoteId};
        formData.append('policy', JSON.stringify(data));
        files.forEach(it => formData.append('files', it.file, it.name));
        const headers = {'content-type': 'multipart/form-data'};
        const response = await super.handlePost(`${this.basepath}bind`, formData, {headers});
        super.handleResponse(response);
        return response.data;
    }

    async mtaPolicy(paymentSource, sequenceId, meta, submission, duration, effectiveFrom, attachments, files, payFrequency = null) {
        const formData = new FormData();
        const data = {meta, submission, duration, effectiveFrom, payFrequency, paymentSource, attachments};
        formData.append('policy', JSON.stringify(data));
        files.forEach(it => formData.append('files', it.file, it.name));
        const headers = {'content-type': 'multipart/form-data'};
        const response = await super.handlePost(`${this.basepath}${sequenceId}`, formData, {headers});
        super.handleResponse(response);
        return response.data;
    }

    async mtaPolicyPreview(sequenceId, meta, submission, duration, effectiveFrom, payFrequency = null) {
        const data = {meta, submission, duration, effectiveFrom, payFrequency};
        const response = await super.handlePost(`${this.basepath}${sequenceId}/preview`, data);
        super.handleResponse(response);
        return response.data;
    }

    async getSequences(policyIds, load) {
        const config = {params: {policyIds}};
        this.addLoadParams(load, config);
        // console.log('params', config)
        const response = await super.handleGet(this.basepath, config);
        super.handleResponse(response);
        return response.data;
    }

    async cancelPolicyPreview(policyId, cancelDate, cancelType, cancelReason, description) {
        const cancellationData = {cancelDate, cancelType, cancelReason, description};
        const response = await super.handlePost(`${this.basepath}${policyId}/cancelpreview`, cancellationData);
        super.handleResponse(response);
        return response.data;
    }

    async endorsePolicy(sequenceId, endorsements) {
        const data = {issueEndorsements: endorsements};
        console.info(data);
        const response = await super.handlePost(`${this.basepath}${sequenceId}/endorse`, data);
        super.handleResponse(response);
        return response.data;
    }

    async getPolicyBySequenceId(sequenceId) {
        const response = await super.handleGet(`${this.basepath}${sequenceId}`);
        super.handleResponse(response);
        return response.data;
    }

    async getTransaction(id) {
        const response = await super.handleGet(`${this.basepath}transaction/${id}`);
        super.handleResponse(response);
        return response.data;
    }

    async getPoliciesCsv(limit = 500, offset = 0) {
        const config = {params: {limit, offset}};
        // We want to load all fields for the CSV.
        this.addLoadParams(defaultLoad, config);
        const response = await super.handleGet(`${this.basepath}?format=csv`, config);
        super.handleResponse(response);
        return response.data;
    }

    async requestPayment(paymentSource, transactionId) {
        const response = await super.handlePost(`${this.basepath}transaction/${transactionId}/pay`, {paymentSource});
        super.handleResponse(response);
        return response.data;
    }

    async cancelPolicy(paymentSource, policyId, cancelDate, cancelType, cancelReason, description) {
        const cancellationData = {cancelDate, cancelType, cancelReason, description, paymentSource};
        const response = await super.handlePost(`${this.basepath}${policyId}/cancel`, cancellationData);
        super.handleResponse(response);
        return response.data;
    }

    addLoadParams(load, config) {
        load.forEach(it => {
            config.params[it] = true;
        });
    }

    async getRenewalById(renewalId) {
        const response = await super.handleGet(`${this.basepath}renewal-quote/${renewalId}`);
        super.handleResponse(response);
        return response.data;
    }

    async requestRenewalById(sequenceId, previousSequenceId, status, renewalId) {
        const response = await super.handlePost(`${this.basepath}renewal-quote/${renewalId}`, {sequenceId, previousSequenceId, status});
        super.handleResponse(response);
        return response.data;
    }

    async requestRenewal(sequenceId, previousSequenceId, status, execute) {
        const response = await super.handlePost(`${this.basepath}renewal-quote`, {sequenceId, previousSequenceId, status, execute});
        super.handleResponse(response);
        return response.data;
    }

    async requestRenewalBind(policyId, paymentSource) {
        const response = await super.handlePost(`${this.basepath}${policyId}/bind`, {paymentSource});
        super.handleResponse(response);
        return response.data;
    }

    async getRenewalPolicy(previousPolicyIds, load = defaultLoad) {
        const config = {params: {previousPolicyIds}};
        this.addLoadParams(load, config);
        const response = await super.handleGet(`${this.basepath}?statuses=Quoted`, config);
        super.handleResponse(response);
        return response.data;
    }
}
